import React, { FC } from 'react';
import { SanityBlock } from 'graphql-types';
import { SanityRichText } from 'components/Common/SanityRichText';
import { Container } from './RichTextBlock2.styles';
import { FontColorWrapper } from 'components/StandardRichText/StandardRichText.styles';

export const RichTextBlock2: FC<{
  text?: SanityBlock[];
  backgroundColor?: string;
  mobileColor?: string;
  desktopColor?: string;
  textContainer?: React.ComponentType;
}> = ({ text, backgroundColor, textContainer, mobileColor, desktopColor }) => {
  const TextContainer = textContainer ?? React.Fragment;
  return (
    <Container background={backgroundColor}>
      <TextContainer>
        <FontColorWrapper colorMobile={mobileColor} colorDesktop={desktopColor}>
          <SanityRichText blocks={text} />
        </FontColorWrapper>
      </TextContainer>
    </Container>
  );
};
