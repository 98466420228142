import React, { FC, useMemo } from 'react';
import {
  SanityComparisonChartFragment,
  SanityComparisonChartColumn,
  SanityComparisonChartColumnItem,
  SanityComparisonChartRow,
} from 'graphql-types';
import {
  Container,
  ChartContainer,
  Column,
  Corner,
  RowHeader,
  ColumnHeader,
  Cell,
  ComparisonCheck,
  ComparisonX,
  PrimaryBrandLogo,
  RowHeaderColumn,
  Background,
  Title,
  PrimaryBrandColumnHeader,
  DisclaimerContainer,
} from './ComparisonChart.styles';
import { RichTextBlock2Sanity } from 'components/RichTextBlock2';

export const Chart: FC<SanityComparisonChartFragment> = ({
  title,
  comparisonColumns,
  comparisonRows,
  chartDisclaimer,
}) => {
  const renderColumns = useMemo(
    () =>
      comparisonColumns?.map(
        ({
          _key,
          columnImage,
          columnTitle,
          comparisonColumnItems,
        }: SanityComparisonChartColumn) => {
          return (
            <Column key={_key}>
              {columnImage && (
                <PrimaryBrandColumnHeader>
                  <PrimaryBrandLogo
                    image={columnImage?.asset?.gatsbyImageData}
                    alt="Brand Logo"
                    objectFit="contain"
                  />
                </PrimaryBrandColumnHeader>
              )}
              {!columnImage && <ColumnHeader>{columnTitle}</ColumnHeader>}

              {comparisonColumnItems?.map(
                (
                  ComparisonChartColumnItem: SanityComparisonChartColumnItem,
                  index: number
                ) => {
                  const {
                    _key,
                    value,
                    booleanValue,
                  } = ComparisonChartColumnItem;
                  const evenCell = index % 2 === 0;
                  return (
                    <Cell
                      key={_key}
                      backgroundColor={evenCell ? 'dark' : 'light'}
                    >
                      {value ||
                        (booleanValue === true ? (
                          <ComparisonCheck />
                        ) : (
                          <ComparisonX />
                        ))}
                    </Cell>
                  );
                }
              )}
            </Column>
          );
        }
      ),
    [comparisonColumns]
  );

  const renderRows = useMemo(
    () =>
      comparisonRows?.map(
        (ComparisonChartRow: SanityComparisonChartRow, index: number) => {
          const { _key, rowTitle } = ComparisonChartRow;
          const evenCell = index % 2 === 0;
          return (
            <RowHeader
              key={`${_key}`}
              backgroundColor={evenCell ? 'dark' : 'light'}
            >
              {rowTitle}
            </RowHeader>
          );
        }
      ),
    [comparisonRows]
  );

  return (
    <Background>
      <Title>{title}</Title>
      <Container>
        <ChartContainer>
          <RowHeaderColumn>
            <Corner></Corner>
            {renderRows}
          </RowHeaderColumn>
          {renderColumns}
        </ChartContainer>
        {chartDisclaimer && (
          <RichTextBlock2Sanity
            fields={chartDisclaimer}
            textContainer={DisclaimerContainer}
          />
        )}
      </Container>
    </Background>
  );
};
