import { SanityComparisonChartFragment } from 'graphql-types';
import { useIsMobile } from 'hooks/use-media-query';

import React, { FC } from 'react';
import { useIsBrowser } from 'utils/general';
import { Chart } from './Chart';
import { MobileChart } from './MobileChart';

export const ComparisonChart: FC<SanityComparisonChartFragment> = (props) => {
  const isBrowser = useIsBrowser();
  const isMobile = useIsMobile();

  return isMobile && isBrowser ? (
    <MobileChart {...props} />
  ) : (
    <Chart {...props} />
  );
};
