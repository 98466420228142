import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  SanityComparisonChartFragment,
  SanityComparisonChartColumn,
  SanityComparisonChartColumnItem,
  SanityComparisonChartRow,
} from 'graphql-types';
import {
  MobileChartContainer,
  MobileColumnHeader,
  ComparisonSelection,
  MobilePrimaryBrandColumn,
  MobileRowHeader,
  MobileHeaderText,
  ComparisonCheck,
  ComparisonX,
  Cell,
  MobileColumn,
  PrimaryBrandMobileColumn,
  MobileColumnContainer,
  MobileLogo,
  Background,
  Title,
  DisclaimerContainer,
} from './ComparisonChart.styles';
import { RichTextBlock2Sanity } from 'components/RichTextBlock2';

export const MobileChart: FC<SanityComparisonChartFragment> = ({
  title,
  comparisonColumns,
  comparisonRows,
  chartDisclaimer,
}) => {
  const [selection, setSelection] = useState<string | undefined>();
  const [selectOptions, setSelectOptions] = useState<
    SanityComparisonChartColumn[] | undefined
  >();

  useEffect(() => {
    setSelectOptions(
      comparisonColumns?.slice(1) as SanityComparisonChartColumn[]
    );
  }, [comparisonColumns]);

  const onSelectionChange = (value: string) => {
    setSelection(value);
  };

  const selectedColumn =
    selectOptions?.find((col) => col?.columnTitle === selection) ||
    (selectOptions && selectOptions[0]);

  const primaryBrand = comparisonColumns?.[0];

  const columnImage = primaryBrand?.columnImage;

  const renderColumn = (
    comparisonColumn: SanityComparisonChartColumn,
    isPrimaryBrand: boolean
  ) => {
    const { _key, comparisonColumnItems } = comparisonColumn;
    const Column = isPrimaryBrand ? PrimaryBrandMobileColumn : MobileColumn;
    return (
      <Column key={_key}>
        {comparisonColumnItems?.map(
          (
            { _key, value, booleanValue }: SanityComparisonChartColumnItem,
            index: number
          ) => {
            const evenCell = index % 2 === 0;
            return (
              <Cell
                key={_key}
                backgroundColor={evenCell ? 'dark' : 'light'}
                primaryBrand={isPrimaryBrand}
              >
                {value ||
                  (booleanValue === true ? (
                    <ComparisonCheck />
                  ) : (
                    <ComparisonX />
                  ))}
              </Cell>
            );
          }
        )}
      </Column>
    );
  };

  const renderRows = useMemo(
    () =>
      comparisonRows?.map(
        ({ _key, rowTitle }: SanityComparisonChartRow, index: number) => {
          const evenCell = index % 2 === 0;
          return (
            <MobileRowHeader
              key={`${_key}`}
              backgroundColor={evenCell ? 'dark' : 'light'}
            >
              <MobileHeaderText>{rowTitle}</MobileHeaderText>
            </MobileRowHeader>
          );
        }
      ),
    [comparisonRows]
  );

  if (!selectOptions) return null;

  return (
    <Background>
      <Title>{title}</Title>
      <MobileChartContainer>
        <MobileColumnHeader>
          {columnImage ? (
            <MobileLogo
              image={columnImage?.asset?.gatsbyImageData}
              alt="Brand Logo"
              objectFit="contain"
            ></MobileLogo>
          ) : null}
          <ComparisonSelection
            changeHandler={onSelectionChange}
            value={selection}
            items={
              selectOptions?.map((col: SanityComparisonChartColumn) => {
                return { text: col?.columnTitle || '' };
              }) || []
            }
          ></ComparisonSelection>
        </MobileColumnHeader>
        <MobileColumnContainer>
          <MobilePrimaryBrandColumn>{renderRows}</MobilePrimaryBrandColumn>
          {primaryBrand && renderColumn(primaryBrand, true)}
          {selectedColumn && renderColumn(selectedColumn, false)}
        </MobileColumnContainer>
        {chartDisclaimer && (
          <RichTextBlock2Sanity
            fields={chartDisclaimer}
            textContainer={DisclaimerContainer}
          />
        )}
      </MobileChartContainer>
    </Background>
  );
};
