import React, { FC } from 'react';
import { SanityRichTextBlock2Fragment } from 'graphql-types';
import { RichTextBlock2 } from './RichTextBlock2';

export const RichTextBlock2Sanity: FC<{
  fields: SanityRichTextBlock2Fragment;
  textContainer?: React.ComponentType;
}> = ({ fields, textContainer }) => {
  return (
    <RichTextBlock2
      backgroundColor={fields.backgroundColor?.hex}
      text={fields._rawText}
      textContainer={textContainer}
      mobileColor={fields.mobileColor?.hex}
      desktopColor={fields.desktopColor?.hex}
    />
  );
};
