import React from 'react';
import { graphql } from 'gatsby';
import { LadderProductPageBySlugQuery } from 'graphql-types';
import { getProductContextData } from 'Context/Product/Product.helper';
import { ProductProvider } from 'Context/Product/Product.context';
import { useLocation } from '@reach/router';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { PDPPageContainer } from 'components/Pdp';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { useLogPageViewed } from 'helpers/Amplitude';
import { renderProductPageBlock } from './ProductPage.blocks';
import { useCollection } from 'utils/url';
import { Breadcrumb } from 'components/Product/Breadcrumb/Breadcrumb';
import { LadderApp } from 'templates/ladder/LadderApp';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

interface LadderProductTemplateProps {
  data: LadderProductPageBySlugQuery;
}

export const LadderProductTemplate: React.FC<LadderProductTemplateProps> = ({
  data,
}) => {
  const {
    slug,
    product,
    defaultCollection,
    defaultCollectionAnchor,
    defaultSku,
    seoMetadata,
    blocks: originalBlocks,
    pageBlocksOverride: forcedBlocks,
  } = data.page || {};

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  if (!product) {
    throw new Error('Product not returned with query');
  }

  const blocks = forcedBlocks?.blocks || originalBlocks;

  const { search } = useLocation();

  const contextData = getProductContextData(
    product,
    new URLSearchParams(search).get('sku') || defaultSku
  );

  const { collectionTitle, collectionUrl } = useCollection(
    defaultCollection,
    defaultCollectionAnchor
  );

  return (
    <LadderApp>
      {seoMetadata && (
        <SeoMetadata
          metaData={seoMetadata}
          slug={`products/${slug?.current}`}
        />
      )}
      <ProductProvider {...contextData} product={product}>
        <PDPPageContainer>
          <Breadcrumb
            collectionTitle={collectionTitle}
            collectionUrl={collectionUrl}
            pageTitle={product.name || ''}
          />
        </PDPPageContainer>
        {blocks
          ?.map((block) => block && renderProductPageBlock(block, data.page))
          .map((block, index) => (
            <ImageLoadingWrapper
              key={index}
              eager={index < 2}
              children={block}
            />
          ))}
      </ProductProvider>
    </LadderApp>
  );
};

export default LadderProductTemplate;

export const query = graphql`
  query LadderProductPageBySlug($slug: String!) {
    page: sanityLadderProductPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      name
      defaultSku
      seoMetadata {
        ...SeoMetadata
      }
      ...LadderProductInfo
      ...LadderProductPageBlocks
      pageBlocksOverride {
        ...LadderProductPageBlocks
      }
    }
  }
`;
