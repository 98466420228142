import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  LadderProductPageBySlugQuery,
  ProductReviewsBlockFragment,
  SanityEmailSignupBlockFragment,
  SanitySellPageSpacer,
  SanitySimpleMediaWithTextBlockFragment,
  SanitySquareImageSliderFragment,
  AddToCartBlockFragment,
  SanityProductAboutFragment,
  SanityProductFaqFragment,
  SanityLadderProductSliderFragment,
  SanityComparisonChartFragment,
} from 'graphql-types';

import { ProductFAQSanity } from 'components/Product/ProductFAQ/ProductFAQSanity';
import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { ProductAboutSanity } from 'components/Product/ProductAbout/ProductAbout.sanity';
import { AddToCartBlock } from 'components/Product/AddToCartBlock/AddToCartBlock';
import { SanityReviewSection } from 'components/Product/ReviewSection';
import { SanitySquareImageSlider } from 'components/SquareImageSlider/SanitySquareImageSlider';
import { Spacer } from 'components/Spacer/Spacer';
import { PDPPageContainer } from 'components/Pdp';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { DEFAULT_BUY_BUTTON_ACTION } from 'Constants';
import { ComparisonChart } from 'components/Product/ComparisonChart/ComparisonChart';

type BlocksArrayType = NonNullable<
  LadderProductPageBySlugQuery['page']
>['blocks'];

export const renderProductPageBlock = (
  block: BlockType<BlocksArrayType>,
  data: LadderProductPageBySlugQuery['page']
) => renderBlockWithId(block, getBlock(block, data));

function getBlock(
  block: BlockType<BlocksArrayType>,
  data: LadderProductPageBySlugQuery['page']
) {
  const { product, seoMetadata } = data || {};
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityProductAddToCart':
      return (
        <PDPPageContainer>
          <AddToCartBlock
            addToCartAction={
              ((block as AddToCartBlockFragment).buyButtonOption as
                | 'checkout'
                | 'cart') || DEFAULT_BUY_BUTTON_ACTION
            }
          />
        </PDPPageContainer>
      );
    case 'SanityProductReviewsBlock':
      return (
        <SanityReviewSection
          reviewId={product?.reviewId}
          review={product?.review}
          staticReviewData={product?.staticReviewData}
          product={{
            title: product?.name,
            description: product?.description,
            image: seoMetadata?.openGraphImage?.image?.asset?.url,
          }}
          key={id}
          fields={block as ProductReviewsBlockFragment}
        />
      );
    case 'SanityLadderProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityLadderProductSliderFragment}
        />
      );
    case 'SanityProductAbout':
      return (
        <ProductAboutSanity
          key={id}
          fields={block as SanityProductAboutFragment}
        />
      );
    case 'SanityProductFAQ':
      return (
        <ProductFAQSanity key={id} fields={block as SanityProductFaqFragment} />
      );

    case 'SanitySquareImageSlider':
      return (
        <SanitySquareImageSlider
          key={id}
          fields={block as SanitySquareImageSliderFragment}
        />
      );
    case 'SanityComparisonChart':
      return (
        <PDPPageContainer>
          <ComparisonChart
            key={id}
            {...(block as SanityComparisonChartFragment)}
          />
        </PDPPageContainer>
      );
    // Removed blocks
    case 'SanityProp65Block':
      return null;
    default:
      return `Unrecognized block type: ${type}`;
  }
}
